.footer{
    padding-top: 70px;
    padding-bottom: 30px;
}


.footer .logo img{
     width: 30%;
}

.footer .logo p{
    color: var(--text-color);
}

.social__links span a{
    text-decoration: none;
    color: var(--heading-color);
    font-size: 1.2rem;
}

.footer__links-title{
    color: var(--heading-color);
    margin-bottom: 1rem;
}

li{
    color: white;
}

.footer__logo{
    margin-left: 5%;
    width: 10%;
}
.footer__address{
    width: 30%;
    margin-left: 0;
}
.footer__contact{
    width: 30%;
    margin-left: 0;
}
.footer__quick-links li a{
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.1rem;
}

.footer__quick-links h6 span i{
    color: var(--secondary-color);
    font-size: 1.1rem;
}

.copyright{
    color: var(--text-color);
    font-size: 1rem;
}

@media only screen and (max-width: 991px){
    .footer-flex{
        
        display: flex;
    }
}