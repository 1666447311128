section{
    margin-top: 1.5rem;
    text-align: center;
    align-items: center;
}
h2{
    margin-top: 1rem;
    text-align: center;
    align-items: center;
    font-size: 1.5rem;
}

p{
    text-align: center;
}

.service{
    padding-top: 1.5rem;
    color: white;
    margin-top: 2rem;
    height: 100vh;
    width: 100%;
    background-image: url("../Images/Background.png");
    background-position: center;
    z-index: 0;
}

.service h1{
    margin-top: 2rem;
    text-align: center;
    font-size: 2.5rem;
}

.service h2{
    margin-top: 3rem;
}

.consultantImage{
    height: 3rem;
    width: 3rem;
    opacity: .5;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-image: url("../Images/question-answer-fill.svg");

}

.container hr{
    background-color: white;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}
/* Principles */

.principle__section{
    margin-top: 2rem;
    text-align: center;
    align-items: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.principle__section h1{
    text-align: center;
}

/* contact */

.contact{
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}