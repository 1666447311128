.service__item{
    padding: 1rem;
    border-radius: 0.5rem;
}

.service__item h3{
    text-decoration: none;
    color: #ffffff;
}
.brand{
    text-decoration: none;
}
.service__img{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: var(--secondary-color);
    margin-top: 20%;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    line-height: 50px;
}

.service__img img{
   width: 2 rem; 
}

@media only screen and (max-width: 991px){
    .service__img{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: var(--secondary-color);
        margin-top: 10%;
        padding: .5rem;
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;
        line-height: 50px;
    }

    .service__img img{
        width: 2rem; 
     }

     .service__item{
        padding: .5rem;
        border-radius: 0.5rem;
    }
}

.service__item h5{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.service__item p{
    font-size: 1rem;
    color: #ffffff;
    opacity: .6;
}