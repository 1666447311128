:root {
    --main-theme-color: #5b6fe6;
    --dark-bg-color: #f8f9ff;
    --heading-color: #ffffff;
    --background-color: #5b6fe6;
    --nav-link: #000;
    --paragraph-color: #dfe2e9;
    --banner-paragraph-color: #e9ecf1;
    --paragraph-color-II: #000;
}

/*text color*/
 

.single-blog-post .post-content h3 a,
.single-box a:hover h4,
a.text-link:hover,
.widget-link-items li a:hover,
.title-right-icon li:nth-child(1) i,
.seat li:nth-child(1) i {
    color: var(--main-theme-color);
}
